// Footer.scss adjustments
.footerContainer {
  background-color: #2e3b55;
  color: #fff;
  text-align: center;
  padding: 16px 24px;
  margin-top: auto;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

  .footerText {
    margin: 8px 0;

    a {
      // Targeting anchor tags directly
      color: #fff; // Ensuring the email link is white
      text-decoration: none; // Optional: removes underline from links

      &:hover {
        color: #ffd700; // Maintains the hover effect color change if desired
        text-decoration: underline; // Optional: adds underline on hover
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .footerHeading {
    margin-bottom: 12px;
    color: #fff;
  }

  .footerLink {
    color: #fff;
    &:hover {
      color: #ffd700;
    }
  }
}
