.poppinsFont {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
  }
  .oswaldFont {
    font-family: 'Oswald', sans-serif !important;
    font-weight: 400 !important;
  }


.quoteFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #7272726e;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    .formTitle {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }
  
    .formField {
      margin: 10px 0;
      width: 100%;
    }
  
    .submitButton {
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: darken(#007bff, 10%);
      }
    }
  }
  