// Home.module.scss
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

.poppinsFont {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
}
.oswaldFont {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 400 !important;
}


.heroBanner {
  position: relative; // Make this a positioned ancestor
  background-image: url("../../../public/assets/images/Hero-Banner-V3.png");
  background-size: cover; // Cover will ensure the image covers the entire div
  background-repeat: no-repeat;
  text-align: flex-end; // Aligns the content to the right
  background-position: center; // Center the image
  color: #fff;
  width: 100%;
  padding-top: 50vh; // Adjust this value to position your content within the banner area
  padding-bottom: 10vh;

  .heroCTA {
    position: absolute;
    right: 1vw; // Using viewport width unit to keep the button responsive and on the right
    bottom: -7h; // Using viewport height unit to align the button from the bottom
    // padding: 10px 30px;
    background-color: #c70000; // Example: A blue shade
    color: white; // Text color

    font-size: 1.2rem;

    &:hover {
      background-color: darken(#c70000, 10%);
    }
  }
}

// Media query for mobile devices
@media (max-width: 768px) {
  .heroBanner {
    background-image: url("../../../public/assets/images/Mobile-Hero-Banner-V3.png"); // Path for mobile banner
    background-size: contain; // Ensures the entire image is visible
    background-repeat: no-repeat; // Prevents the image from repeating
    background-position: top center; // Centers the background image
    width: 100%;
    height: 115vh; // Adjusts the height to fill the screen
    display: flex;
    justify-content: flex-end; // Center content horizontally
    align-items: flex-end; // Align the CTA button towards the bottom
    padding-bottom: 20px; // Space for the CTA button

    .heroCTA {
      position: absolute;
      right: 10vw; // Adjust based on your design for mobile
      bottom: 5%; // Adjust for mobile layout
    }
  }
}
