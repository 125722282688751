// Navbar.scss
.navbarContainer {
    .appBar {
      background-color: #2E3B55; // A deep shade of blue
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
  
    .menuButton {
      color: #FFF;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  
    .logo {
      height: 50px;
      transition: transform 0.2s ease-in-out;
  
      &:hover {
        transform: scale(1.1);
      }
    }
  
    .welcomeMessage {
      color: #FFF;
      margin-right: auto;
      padding-left: 20px;
    }
  
    .loginButton, .registerButton {
      color: #FFF;
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
  