@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
.poppinsFont {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
}
.oswaldFont {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 400 !important;
}

.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  }
}
.card {
  max-width: 375px; // Maintain the max-width or adjust as needed
  min-width: 375px; // Adjust this value to make the card wider
  box-shadow: 3px 3px 10px rgba(0,0,0,0.1); // Soft shadow for a subtle effect
}

// Add hover effects for the Card
.card:hover {
  box-shadow: 5px 5px 15px rgba(0,0,0,0.2); // More pronounced shadow on hover
}