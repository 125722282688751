.policy-container {
    margin-top: 20px;
  
    .policy-heading {
      font-size: 28px;
      color: #333;
      margin-bottom: 20px;
    }
  
    .policy-subheading {
      font-size: 22px;
      color: #555;
      margin-top: 15px;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 16px;
      line-height: 1.6;
      color: #666;
    }
  }
  